.card {
  width: 72vw;
  transition: all 1s linear;
  padding: 16px;
}

@media (max-width: 1410px) {
  .card {
    width: 70vw;
    transition: all 1s linear;
  }
}
@media (max-width: 1318px) {
  .card {
    width: 69vw;
    transition: all 1s linear;
  }
}
@media (max-width: 1248px) {
  .card {
    width: 67vw;
    transition: all 1s linear;
  }
}

@media (max-width: 1199px) {
  .card {
    width: 95vw;
    transition: all 1s linear;
  }
}

.table {
  width: 1000px;
}
.key {
  width: 86px;
}
.value {
  width: 120px;
}
